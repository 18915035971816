import React from 'react';
import './Hero.css';
import hero from '../Images/Hero.png';

const Hero = () => {
  return (
    <section className="hero">
     <div className="hero-image">
        {/* <img src={hero} alt="InternHubb" /> */}
      </div>
      <div className="hero-content">
        <h1>Your Ultimate Destination for Internships</h1>
        <p>Connecting students and recent graduates with companies looking for fresh talent.</p>
        <button className="read-more">Read More</button>
      </div>
     
    </section>
  );
};

export default Hero;

import React from "react";
import "./InternshipCards.css"; // Add your CSS file

const InternshipCards= () => {
  return (
    <div className="App">
      {/* Search Bar */}
      <div className="search-bar">
        <input type="text" placeholder="Internship" />
        <input type="text" placeholder="Pune" />
        <button>Search</button>
      </div>

      {/* Cards Section */}
      <section className="card-section">
        <CardList />
      </section>

      {/* Pagination */}
      <div className="pagination">
        <span>{"<"}</span>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <span key={number} className="page-number">
            {number}
          </span>
        ))}
        <span>{">"}</span>
      </div>

      {/* Recommended Section */}
      <section className="recommended-section">
        <h2>Recommended for you</h2>
        <CardList />
      </section>
    </div>
  );
};

// CardList Component for displaying jobs
const CardList = () => {
  const jobData = [
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    { location: "Work from Home", salary: "7,00,000 - 12,00,000 / year", workFromHome: true },
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    { location: "Work from Home", salary: "7,00,000 - 12,00,000 / year", workFromHome: true },
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    { location: "Work from Home", salary: "7,00,000 - 12,00,000 / year", workFromHome: true },
    { location: "Pune", salary: "7,00,000 - 12,00,000 / year", workFromHome: false },
    // Add more jobs as needed
  ];

  return (
    <div className="card-list">
      {jobData.map((job, index) => (
        <JobCard key={index} job={job} />
      ))}
    </div>
  );
};

// JobCard Component
const JobCard = ({ job }) => {
  return (
    <div className="job-card">
      <div className="job-status">Actively hiring</div>
      <h3>Digital Marketing Manager</h3>
      <p>Radiant IT services Pvt. Ltd.</p>
      <p>
        <i className="location-icon" /> {job.workFromHome ? "Work from Home" : job.location}
      </p>
      <p>
        <i className="salary-icon" /> {job.salary}
      </p>
      <button className="apply-button">Apply Now</button>
    </div>
  );
};

export default InternshipCards;

import React, { useState } from "react";
import "./PopupForm.css";

const PopupForm = ({ closePopup }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    contact: "",
    highestQualification: "",
    experience: "",
    position: "",
    file: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({ ...prevState, file: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    // Prepare form data for submission
    const formDataObject = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataObject.append(key, formData[key]);
    });

    try {
      const response = await fetch("https://internhubb.in//Backend/job-application.php", {
        method: "POST",
        body: formDataObject,
      });

      if (response.ok) {
        const result = await response.json();
        setMessage("Application submitted successfully!");
        setFormData({
          fullname: "",
          email: "",
          contact: "",
          highestQualification: "",
          experience: "",
          position: "",
          file: null,
        });
      } else {
        const error = await response.json();
        setMessage(error.message || "Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("An error occurred. Please try again.");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={closePopup}>
          &times;
        </span>
        <h2>Job Application Form</h2>
        {message && <p className="message">{message}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="fullname"
            placeholder="Full Name"
            value={formData.fullname}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="contact"
            placeholder="Please enter a 10-digit mobile number"
            value={formData.contact}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="highestQualification"
            placeholder="Highest Qualification"
            value={formData.highestQualification}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="experience"
            placeholder="Work Experience"
            value={formData.experience}
            onChange={handleChange}
            required
          />
          <select
            name="position"
            value={formData.position}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Position
            </option>
            <option value="Intern">Intern</option>
            <option value="Developer">Developer</option>
            <option value="Manager">Manager</option>
          </select>
      
          <div className="file-container">
  <label className="file-label" htmlFor="file">
    Upload Resume
  </label>
  <input
    type="file"
    id="file"
    name="file"
    onChange={handleFileChange}
    required
    className="file-input"
  />
</div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;

import React from 'react';
import './NContact.css'; // Create the necessary CSS file for styling

// Import the images from src/Images
import FacebookIcon from '../Images/Facebook.png';
import WhatsAppIcon from '../Images/Whatsapp.png';
import LinkedInIcon from '../Images/Linkedin.png';
import InstagramIcon from '../Images/Instagram.png';
import YouTubeIcon from '../Images/YouTubeIcon.png';

const NContact = () => {
  return (
    <div className="contact-container2">
      <div className="contact-info2">
        <span>📞 +91 8669168920 | +91 7420002088</span>
        <span>📧 info@internhubb.in</span>
        <span>📧 hr@internhubb.in</span>
      </div>
      <div className="social-icons">
        <a href="https://facebook.com" target="_blank" rel="noreferrer">
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        <a href="https://whatsapp.com" target="_blank" rel="noreferrer">
          <img src={WhatsAppIcon} alt="WhatsApp" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noreferrer">
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noreferrer">
          <img src={YouTubeIcon} alt="YouTube" />
        </a>
      </div>
    </div>
  );
};

export default NContact;

import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>
          Office no 01, 1st Floor, J-Tower,<br />
          Shriram Nagar, Padampura,<br />
          Chhatrapati Sambhaji Nagar,<br />
          Maharashtra 431005
        </p>
        <p>📞 +91 866 916 8920</p>
        <p>📞 +91 937 222 6748</p>
        <p>📧 info@internhubb.in</p>
      </div>
      <div className="contact-form">
        <h2>Get In Touch</h2>
        <p>Feel free to drop us a line below</p>
        <form>
          <input type="text" placeholder="Your Name" />
          <input type="email" placeholder="Your Email" />
          <textarea placeholder="Type your message here" rows="5" />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
import React, { useState } from 'react';
import './Testimonials.css';

const testimonials = [
  {
    name: "Sit Smith",
    text: "The application process was straightforward and seamless. I had a great experience using this platform, and it really helped me secure my internship.",
    rating: 5
  },
  {
    name: "John Doe",
    text: "InternHubb made finding the perfect internship a breeze. Highly recommend to anyone looking to kickstart their career!",
    rating: 4
  },
  {
    name: "Jane Roe",
    text: "Fantastic experience! I learned so much during my internship and InternHubb played a huge role in making that possible.",
    rating: 5
  },
  {
    name: "Alice Johnson",
    text: "The platform is user-friendly and gave me plenty of opportunities. Couldn't have asked for a better experience!",
    rating: 4
  },
  {
    name: "Michael Brown",
    text: "Great platform, intuitive design, and smooth functionality. InternHubb is a game changer!",
    rating: 5
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 3;

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + visibleCards) % testimonials.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - visibleCards + testimonials.length) % testimonials.length);
  };

  const getVisibleTestimonials = () => {
    let start = currentIndex;
    let end = start + visibleCards;
    return testimonials.slice(start, end).concat(
      testimonials.slice(0, Math.max(0, end - testimonials.length))
    );
  };

  return (
    <section className="testimonials-section">
      <h2 className="testimonials-header">What Our Candidates Say</h2>
      <div className="carousel-container">
        <button className="carousel-btn prev" onClick={goToPrev}>❮</button>
        <div className="testimonials-carousel">
          {getVisibleTestimonials().map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <p className="testimonial-text">"{testimonial.text}"</p>
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-rating">Rating: {testimonial.rating} / 5</p>
            </div>
          ))}
        </div>
        <button className="carousel-btn next" onClick={goToNext}>❯</button>
      </div>
    </section>
  );
};

export default Testimonials;

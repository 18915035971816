import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import HomePage from './Screens/HomePage';
import About from './Components/About';
import FullStackCourse from './Courses/FullStackCourse';
import Testimonials from './Components/Testimonials';
import Partners from './Components/Partners';
import SignInForm from './Screens/SignInForm';
import Login from './Screens/Login';
import InternshipCards from './Screens/InternshipCards';

import ContactForm from './Screens/ContactForm';



function App() {
  return (
    <Router>
      <div className="App">
        {/* Header - Common Across All Pages */}
        <Header />

        {/* Main Content */}
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />}   />
         <Route path="FullStackCourse " element={<FullStackCourse />} />
          <Route path="SignIn" element={<SignInForm />} />
          <Route path="Login" element={<Login />} />

          <Route path="InternshipCards" element={<InternshipCards />} />
          
          
          
          {/* Individual Routes for Other Sections */}
         
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/ContactForm" element={<ContactForm />} />
        </Routes>

        {/* Footer - Common Across All Pages */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;

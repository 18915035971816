import React from 'react';
import './Services.css'; // CSS for styling
import s1 from '../Images/industril.png';
import s2 from '../Images/digital.png';
import s3 from  '../Images/website.png';
import s4 from  '../Images/software.png';
import s5 from '../Images/app.png';
import s6 from '../Images/billing.png';

const servicesData = [
  {
    imgSrc: s1,
    title: 'INDUSTRIAL TRAINING',
    description:
      'Elevate your career prospects with hands-on training led by industry experts, setting the foundation for a successful and dynamic professional path.',
  },
  {
    imgSrc: s2,
    title: 'DIGITAL MARKETING',
    description:
      'Internhubb gives you top online visibility. Our online marketing techniques are very useful in generating ROI for businesses.',
  },
  {
    imgSrc: s3,
    title: 'WEBSITE DEVELOPMENT',
    description:
      'In today’s technical era, a website is needed for every business. The Internhubb design and develop a website for brand visibility.',
  },
  {
    imgSrc: s4,
    title: 'SOFTWARE DEVELOPMENT',
    description:
      'Internhubbis the best software development company in the industry, which gives more reliable Applications.',
  },
  {
    imgSrc: s5,
    title: 'MOBILE APP DEVELOPMENT',
    description:
      'Internhubb Pvt. Ltd. has the best teams of android app development & iOS app development in the industry.',
  },
  {
    imgSrc: s6,
    title: 'CORPORATE TRAINING',
    description:
      'Empower your workforce with Internhubb Pvt Ltd \'s cutting-edge Corporate Training and Program solutions.',
  },
];

const ServiceCard = ({ imgSrc, title, description }) => (
  <div className="card service-card">
    <img className="card-img-top" src={imgSrc} alt={title} />
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <p className="card-text">{description}</p>
    </div>
  </div>
);

const Services = () => {
  return (
    <div className="container services-container">
      <div className="row justify-content-center">
        {servicesData.map((service, index) => (
          <ServiceCard
            key={index}
            imgSrc={service.imgSrc}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;

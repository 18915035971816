import React from 'react';
import './About.css';


const About = () => {
  return (
    <section className="about-section">
      <h2>About Us</h2>
      <p>
        Welcome to Intern Hubb, your ultimate destination for all things internships! At Intern Hubb, we believe that internships are more than just a stepping stone in your career—they're a pivotal part of your professional journey. Our mission is to bridge the gap between ambitious students and innovative companies, creating a hub where talent meets opportunity.
      </p>
      <p>
        Intern Hubb is a dedicated platform created by a team of passionate professionals with diverse backgrounds in education, career development, and technology. We understand the challenges that students and recent graduates face when searching for internships, and we're here to make the process smoother, more transparent, and incredibly rewarding.
      </p>
      <p>
        Our platform offers a comprehensive range of services designed to help you find the perfect internship. Whether you’re looking for roles in tech, marketing, finance, or any other field, Intern Hubb connects you with top companies seeking fresh talent. Our advanced matching algorithm ensures that you find opportunities tailored to your skills, interests, and career goals.
      </p>
      
      <h2>Our Goals</h2>
      <p>
        We envision a world where every student has access to meaningful internship experiences that pave the way for a successful and fulfilling career. At Intern Hubb, we are committed to empowering the next generation of professionals by providing the tools and opportunities they need to thrive.
      </p>

    
        
      
    </section>
  );
};

export default About;

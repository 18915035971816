import React from 'react';
import './Login.css';


const Login = () => {
  return (
    <div className="login-container">
      

      {/* Login Box */}
      <div className="login-box">
        <h2>Enter Your Details To Sign In</h2>
        <div className="tabs">
          <button className="tab-btn active">CANDIDATES</button>
          <button className="tab-btn">EMPLOYER</button>
        </div>

        <form>
          <input type="email" placeholder="Email Id" required />
          <input type="password" placeholder="Password" required />
          <div className="forgot-password">
            <a href="/">Forgot Password?</a>
          </div>
          <button className="login-submit">Login</button>
        </form>

        <div className="login-footer">
          <p>Don't have an account? <a href="/">Register here</a></p>
          <p>Are you an Employer? <a href="/">Login here</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;

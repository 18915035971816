import React from 'react';
import './FullStackCourse.css'; // External CSS file for styling

const FullStackCourse = () => {
  return (
    <div className="course-container">
      <header className="course-header">
        <h1>Full Stack Development Course</h1>
        <p>By Internhubb</p> {/* Changed company name */}
      </header>

      <section className="course-introduction">
        <h2>About the Course</h2>
        <p>
          Full Stack Development is a comprehensive approach to building both front-end and back-end components of a web application. This course covers everything you need to become a successful full-stack developer, from database management to front-end and back-end development.
        </p>
      </section>

      <section className="course-modules">
        <h2>What You'll Learn</h2>
        <ul>
          <li>HTML, CSS, and JavaScript for front-end development</li>
          <li>React.js for building user interfaces</li>
          <li>Node.js and Express for server-side programming</li>
          <li>MongoDB for database management</li>
          <li>Version control with Git and GitHub</li>
          <li>API development and integration</li>
          <li>Deployment with cloud services</li>
        </ul>
      </section>

      <section className="course-benefits">
        <h2>Why Choose Internhubb?</h2> {/* Changed company name */}
        <ul>
          <li>Learn from industry experts</li>
          <li>Hands-on projects to build your portfolio</li>
          <li>Comprehensive curriculum</li>
          <li>24/7 access to course materials</li>
          <li>Certificate of completion</li>
        </ul>
      </section>

      <section className="course-enroll">
        <h2>Enroll Now</h2>
        <p>
          Take your first step toward becoming a Full Stack Developer with Internhubb's comprehensive course. Start learning today and build your dream career in web development!
        </p>
        <a href="/enroll" className="enroll-button">Enroll Now</a>
      </section>
    </div>
  );
};

export default FullStackCourse;
import React, { useState } from 'react';
import './SignInForm.css';

const SignInForm = () => {
  const [activeTab, setActiveTab] = useState('candidates');

  return (
    <div className="form-container">
      {/* <header className="header">
        <h1 className="logo">INTERNHUBB</h1>
        <nav className="navbar">
          <a href="/">HOME</a>
          <a href="/internship">INTERNSHIP</a>
        </nav>
        <div className="auth-buttons">
          <button className="login-btn">LOGIN</button>
          <button className="register-btn">REGISTER</button>
        </div>
      </header> */}
      
      <div className="form-wrapper">
        <div className="form-background">
          <h2>Enter Your Details To Sign In</h2>
          <div className="tab-buttons">
            <button
              className={`tab-btn ${activeTab === 'candidates' ? 'active' : ''}`}
              onClick={() => setActiveTab('candidates')}
            >
              CANDIDATES
            </button>
            <button
              className={`tab-btn ${activeTab === 'employer' ? 'active' : ''}`}
              onClick={() => setActiveTab('employer')}
            >
              EMPLOYER
            </button>
          </div>

          <form className="form">
            <div className="input-group">
              <input type="text" placeholder="First Name" required />
              <input type="text" placeholder="Last Name" required />
            </div>
            <input type="email" placeholder="Email Id" required />
            <input type="tel" placeholder="Contact No" required />
            <input type="file" placeholder="Resume" required />
            <input type="password" placeholder="Password" required />
            <input type="password" placeholder="Confirm Password" required />
            <button className="register-btn" type="submit">Register</button>
          </form>
          <p className="login-prompt">
            Already a member? <a href="/login" className="login-link">Login</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;

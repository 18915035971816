// Book.js
import React from 'react';
import './Book.css';
import bookImage from '../Images/boy.png'; // Path to boy image

const Book = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="about-us-text">
          
          <h1>Our mission is to empower individuals to acquire new skills.</h1>
          <p>
            In Internhubb, we believe in the transformative power of education.
            Our mission is to empower individuals to acquire new skills and
            empower their knowledge.
          </p>
          <ul className="features-list">
            <li>Quality Teachers</li>
            <li>Online Class</li>
            <li>Lifetime Access</li>
            <li>Get Certified</li>
            <li>Skill Developed</li>
          </ul>
          <button className="read-more-btn">READ MORE</button>
        </div>
        <div className="about-us-image">
          <img src={bookImage} alt="Person reading a book" />
        </div>
      </div>
    </div>
  );
};

export default Book;
